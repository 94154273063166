import { BBox } from 'geojson'
import _ from 'lodash'
import {
  AiForecast,
  AnyForecast,
  AutocompleteResult,
  LiveWind,
  Spot,
} from '../../backend/src/gusty'
import { store } from './index'
import { invalidToken } from './reducer'

type FetchOptions = RequestInit & {
  nullIfNotFound?: boolean
}

export async function fetchJsonOrNull<Output>(
  path: string,
  token: string | null,
  options: FetchOptions = {}
): Promise<Output | null> {
  const init = _.defaultsDeep({}, options, {
    headers: {
      Authorization: token ? `Bearer ${token}` : undefined,
    },
  }) as RequestInit
  const response = await fetch(`/api${path}`, init)

  if (response.status === 401) {
    store.dispatch(invalidToken())
    throw new Error('Unauthorized')
  }

  const nullIfNotFound = options?.nullIfNotFound ?? false
  if (nullIfNotFound && response.status === 404) {
    return null
  }

  if (!response.ok) {
    throw new Error(response.statusText)
  }

  const out = (await response.json()) as Output
  return out
}

export async function fetchJson<Output>(
  path: string,
  token: string | null,
  options?: FetchOptions
): Promise<Output> {
  const out = await fetchJsonOrNull<Output>(path, token, options)
  if (out === null) {
    throw new Error('Not found')
  }
  return out
}

type GetLiveWindParams = {
  token: string
  spotIds: number[]
}

export async function getLiveWind({
  spotIds,
  token,
}: GetLiveWindParams): Promise<Record<string, LiveWind>> {
  return fetchJson(`/liveWind?spots=${spotIds.join(',')}`, token)
}

type GetForecastsParams = {
  forecastId: string
  token: string
}

export async function getForecasts({
  forecastId,
  token,
}: GetForecastsParams): Promise<AnyForecast[]> {
  return fetchJson(`/forecasts?forecastId=${forecastId}`, token)
}

type GetAiForecastParams = {
  forecastId: string
  favoriteSpotIds: number[]
  token: string
}

export async function getAiForecast({
  forecastId,
  favoriteSpotIds,
  token,
}: GetAiForecastParams): Promise<AiForecast> {
  return fetchJson(
    `/aiForecast?forecastId=${forecastId}&favoriteSpotIds=${favoriteSpotIds.join(',')}`,
    token
  )
}

type GetSpotsParams = {
  bounds: BBox
  zoom: number
  token: string
}

export async function getSpots({ bounds, zoom, token }: GetSpotsParams): Promise<Spot[]> {
  const boundsString = bounds.map((b) => b.toFixed(5)).join(',')
  return fetchJson(`/spots?bounds=${boundsString}&zoom=${zoom}`, token)
}

type AutocompleteParams = {
  input: string
  token: string
}

export async function autocomplete({
  input,
  token,
}: AutocompleteParams): Promise<AutocompleteResult[]> {
  return fetchJson(`/autocomplete?input=${input}`, token)
}

export async function login(username: string, password: string): Promise<{ token: string }> {
  return fetchJson('/login', null, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ username, password }),
  })
}
