import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { CircularProgress, Fab, Grid, useMediaQuery } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { useTheme } from '@mui/styles'
import makeStyles from '@mui/styles/makeStyles'
import { delay } from 'bluebird'
import _ from 'lodash'
import { DateTime } from 'luxon'
import PullToRefresh from 'pulltorefreshjs'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { useLocalStorage } from 'react-use'
import { Waypoint } from 'react-waypoint'
import { bayArea } from '../areas/bayArea'
import { hoodRiver } from '../areas/hoodRiver'
import { southTexas } from '../areas/southTexas'
import { ventana } from '../areas/ventana'
import { loadAiForecast, loadForecasts, loadSpots, resetCache } from '../reducer'
import { AiForecastOverview } from './AiForecast'
import DailyForecast from './DailyForecast'
import ExtendedForecast from './ExtendedForecast'
import Footer from './Footer'
import FreeformForecast from './FreeformForecast'
import { LiveWindSummary } from './LiveWindSummary'
import SpotSection, { ForecastWrapper } from './SpotSection'
import SpotsMap from './SpotsMap'
import TopBar from './TopBar'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export type MapSettings = {
  coordinates: {
    lat: number
    lng: number
  }
  zoom: number
  googleMapsZoom: number
}

export type Area = {
  key: string
  name: string
  mapSettings: MapSettings
  forecastId?: string
  spots: {
    title: string
    spotId: number
    forecastSpotId?: number
    liveWindImageUrl?: string
    webcamUrl?: string

    // Clockwise
    offshore?: { start: number; end: number }
  }[]
}

const useStyles = makeStyles((theme) => ({
  sections: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  forecast: {
    padding: theme.spacing(2),
  },
  map: {
    marginTop: theme.spacing(4),
  },
  floatingButton: {
    position: 'fixed !important' as 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}))

export default function App(): JSX.Element {
  const classes = useStyles()
  const dispatch = useDispatch()
  const spotsWithData = useSelector((state) => state.spotsWithData)
  const forecasts = useSelector((state) => state.forecasts)
  const aiForecast = useSelector((state) => state.aiForecast)
  const isLoadingAiForecast = useSelector((state) => state.isLoadingAiForecast)
  const token = useSelector((state) => state.token)
  const location = useLocation()
  const navigate = useNavigate()
  const [defaultArea, setDefaultArea] = useLocalStorage('defaultArea', 'bayArea')
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [buttonType, setButtonType] = useState<'live' | 'forecast'>('live')

  const areaKey = useMemo(() => {
    const search = new URLSearchParams(location.search)
    return search.get('area')
  }, [location.search])

  const area = useMemo(() => {
    const areaString = areaKey ?? defaultArea
    switch (areaString) {
      case 'hoodRiver':
        return hoodRiver
      case 'ventana':
        return ventana
      case 'bayArea':
        return bayArea
      case 'southTexas':
        return southTexas
      default:
        return bayArea
    }
  }, [areaKey, defaultArea])

  const handleAreaChange = useCallback(
    (key: string) => {
      setDefaultArea(key)
      navigate(`/?area=${key}`)
      dispatch(resetCache())
    },
    [dispatch, navigate, setDefaultArea]
  )

  useEffect(() => {
    if (!areaKey && defaultArea) {
      handleAreaChange(defaultArea)
    }
  }, [areaKey, defaultArea, handleAreaChange])

  const fullReload = useCallback(() => {
    if (!token || !area) {
      return
    }
    dispatch(loadSpots({ area, token }))
    if (area.forecastId) {
      dispatch(loadForecasts({ forecastId: area.forecastId, token }))
      const favoriteSpotIds = area.spots.map((spot) => spot.spotId)
      dispatch(loadAiForecast({ forecastId: area.forecastId, favoriteSpotIds, token }))
    }
  }, [area, dispatch, token])

  // Initial data load
  useEffect(() => {
    fullReload()
  }, [fullReload])

  // Timers
  useEffect(() => {
    const interval = setInterval(() => {
      if (!token || !area) {
        return
      }
      dispatch(loadSpots({ area, token }))
    }, 60000)

    const onFocus = () => {
      if (!token || !area) {
        return
      }
      dispatch(loadSpots({ area, token }))
    }

    window.addEventListener('focus', onFocus)

    return () => {
      clearInterval(interval)
      window.removeEventListener('focus', onFocus)
    }
  }, [area, dispatch, token])

  useEffect(() => {
    PullToRefresh.init({
      mainElement: 'body',
      onRefresh: async (): Promise<void> => {
        fullReload()
        await delay(1000)
      },
    })

    return () => {
      PullToRefresh.destroyAll()
    }
  }, [fullReload])

  const handleLiveEnter = useCallback(() => {
    setButtonType('forecast')
  }, [])

  const handleForecastEnter = useCallback(() => {
    setButtonType('live')
  }, [])

  const handleButtonClick = useCallback(() => {
    if (buttonType === 'live') {
      window.location.href = '#live'
    } else {
      window.location.href = '#forecast'
    }
  }, [buttonType])

  if (!area || !spotsWithData) {
    return (
      <Grid container justifyContent="center" style={{ marginTop: 40 }}>
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    )
  }

  const spotSections = _.map(spotsWithData, (spot) => {
    const dailyForecast = forecasts?.find((forecast) => forecast.type === 'daily')
    const spotForecast = _.find(
      dailyForecast?.spots ?? [],
      (s) => s.id === (spot.forecastSpotId ?? spot.spotId)
    )

    let forecastWrapper: ForecastWrapper | undefined
    if (dailyForecast && spotForecast) {
      forecastWrapper = {
        forecast: spotForecast,
        forecastedDay: dailyForecast.forecastedDay,
        forecastCreatedAt: DateTime.fromISO(dailyForecast.createdAt),
        timeZone: dailyForecast.timeZone,
      }
    }

    return (
      <SpotSection
        xs={12}
        md={6}
        lg={4}
        key={spot.spotId}
        spotWithData={spot}
        forecastWrapper={forecastWrapper}
      />
    )
  })

  return (
    <>
      <Waypoint onEnter={handleLiveEnter} />
      <div id="live"></div>
      <TopBar area={area} onAreaChange={handleAreaChange} />
      <LiveWindSummary spotsWithData={spotsWithData} />
      <AiForecastOverview forecast={aiForecast} area={area} isLoading={isLoadingAiForecast} />
      {/* Wrap in div to work around https://material-ui.com/components/grid/#negative-margin */}
      <div className={classes.sections}>
        <Grid container spacing={4}>
          {spotSections}
        </Grid>
      </div>
      <div className={classes.map}>
        <SpotsMap height={600} mapSettings={area.mapSettings} />
      </div>
      {/* Wrap in div to work around https://material-ui.com/components/grid/#negative-margin */}
      <div className={classes.forecast} id="forecast">
        <Waypoint onEnter={handleForecastEnter} />
        <Grid container spacing={4}>
          {forecasts?.map((forecast, forecastIndex) => {
            if (forecast.type === 'daily') {
              return <DailyForecast forecast={forecast} key={`${forecast.type}-${forecastIndex}`} />
            }
            if (forecast.type === 'extended') {
              return (
                <ExtendedForecast forecast={forecast} key={`${forecast.type}-${forecastIndex}`} />
              )
            }
            if (forecast.type === 'freeform') {
              return (
                <FreeformForecast forecast={forecast} key={`${forecast.type}-${forecastIndex}`} />
              )
            }
            return null
          })}
        </Grid>
      </div>
      {isMobile && (
        <Fab className={classes.floatingButton} color="primary" onClick={handleButtonClick}>
          {buttonType === 'live' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
        </Fab>
      )}
      <Footer />
    </>
  )
}
