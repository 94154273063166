import { Grid, Typography } from '@mui/material'
import _ from 'lodash'
import { DateTime } from 'luxon'
import { useMemo } from 'react'
import { FreeformForecast as FreeformForecastType } from '../../../backend/src/gusty'

export default function FreeformForecast({
  forecast,
}: {
  forecast: FreeformForecastType
}): JSX.Element {
  const detailsWithNewLines = _.replace(forecast.details, /\n/gi, '<br>')
  const formattedCreatedAt = DateTime.fromISO(forecast.createdAt)
    .setZone(forecast.timeZone)
    .toLocaleString(DateTime.DATETIME_MED)

  const source = useMemo(() => {
    if (forecast.source === 'masViento') {
      return 'MasViento'
    }
    return forecast.source
  }, [forecast.source])

  return (
    <Grid item xs={12} md={6} style={{ marginTop: '50px' }} lg={6}>
      <section>
        <Typography variant="h4">{source} forecast</Typography>
        <Typography variant="subtitle1">
          <i>{formattedCreatedAt}</i>
        </Typography>
        <Typography
          variant="body1"
          style={{ marginTop: 8 }}
          dangerouslySetInnerHTML={{ __html: detailsWithNewLines }}
        ></Typography>
      </section>
    </Grid>
  )
}
