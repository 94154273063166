import { Grid, Typography } from '@mui/material'
import _ from 'lodash'
import { DateTime } from 'luxon'
import { Fragment, useMemo } from 'react'
import { DailyForecast as DailyForecastType } from '../../../backend/src/gusty'
import * as utils from '../utils'

export default function DailyForecast({ forecast }: { forecast: DailyForecastType }): JSX.Element {
  const detailsWithNewLines = _.replace(forecast.details, /\n/gi, '<br>')
  const formattedCreatedAt = DateTime.fromISO(forecast.createdAt)
    .setZone(forecast.timeZone)
    .toLocaleString(DateTime.DATETIME_MED)

  const source = useMemo(() => {
    if (forecast.source === 'weatherflow') {
      return 'WeatherFlow'
    }
    return forecast.source
  }, [forecast.source])

  const spotRows = _.map(forecast.spots, (spot) => {
    const headerCols = _.map(spot.forecast, (point, pointIndex) => {
      return (
        <td
          key={pointIndex}
          style={{
            textAlign: 'center',
            padding: '5px 10px',
          }}
        >
          <Typography variant="caption">
            <strong>{point.intervals.join(' ')}</strong>
          </Typography>
        </td>
      )
    })

    const speedCols = _.map(spot.forecast, (point, pointIndex) => {
      const backgroundColor = utils.getColorForWindSpeed(point.maxSpeed)
      const textColor = utils.getTextColorForWindSpeed(point.maxSpeed)
      return (
        <td
          key={pointIndex}
          style={{
            background: backgroundColor,
            color: textColor,
            textAlign: 'center',
            padding: '5px 10px',
          }}
        >
          <Typography variant="caption">
            {point.minSpeed} - {point.maxSpeed}
          </Typography>
        </td>
      )
    })

    const directionCols = _.map(spot.forecast, (point, pointIndex) => {
      const backgroundColor = utils.getColorForWindSpeed(point.maxSpeed)
      const textColor = utils.getTextColorForWindSpeed(point.maxSpeed)
      return (
        <td
          key={pointIndex}
          style={{
            background: backgroundColor,
            color: textColor,
            textAlign: 'center',
            padding: '5px 10px',
          }}
        >
          <Typography variant="caption">{point.direction}</Typography>
        </td>
      )
    })

    return (
      <Fragment key={spot.id}>
        <tr key="header">
          <td key="name" style={{ padding: '5px 0' }}>
            <Typography variant="caption">
              <strong>{spot.name}</strong>
            </Typography>
          </td>
          {headerCols}
        </tr>
        <tr key="speed">
          <td key="speed">
            <Typography variant="caption">Speed (kts)</Typography>
          </td>
          {speedCols}
        </tr>
        <tr key="direction">
          <td key="direction">
            <Typography variant="caption">Direction</Typography>
          </td>
          {directionCols}
        </tr>
      </Fragment>
    )
  })

  return (
    <Grid item xs={12} md={6} style={{ marginTop: '50px' }} lg={6}>
      <section>
        <Typography variant="h4">{source} daily forecast</Typography>
        <Typography variant="subtitle1">
          <i>{formattedCreatedAt}</i>
        </Typography>
        <Typography variant="h6" style={{ marginTop: 8 }}>
          {forecast.title}
        </Typography>
        <Typography
          variant="body1"
          style={{ marginTop: 8 }}
          dangerouslySetInnerHTML={{ __html: detailsWithNewLines }}
        ></Typography>
        <div style={{ overflowX: 'auto' }}>
          <table style={{ borderCollapse: 'collapse' }}>
            <tbody>{spotRows}</tbody>
          </table>
        </div>
      </section>
    </Grid>
  )
}
